import { BannerHome } from "../../components/Banners";
import { Navbar } from "../../components/Navbar";
import { Footer } from "../../components/Footer";
import { TimeLine } from "../../components/TimeLine";

import collaborators from "../../assets/Icons/collaborators.svg";
import store from "../../assets/Icons/store.svg";
import graphic from "../../assets/Icons/graphic.svg";
import location from "../../assets/Icons/location.svg";
import graph from "../../assets/Icons/graph.svg";
import collaboratorStar from "../../assets/Icons/collaboratorStar.svg";
import trophy from "../../assets/Icons/trophy.svg";
import mentor from "../../assets/Icons/mentor.svg";
import community from "../../assets/Icons/community.svg";
import handshake from "../../assets/Icons/handshake.svg";
import collaboratorStairs from "../../assets/Icons/collaboratorStairs.svg";
import collaboratorFlag from "../../assets/Icons/collaboratorFlag.svg";
import speedometer from "../../assets/Icons/speedometer.svg";
import gear from "../../assets/Icons/gear.svg";

import {
  AboutContainer,
  Container,
  Icons,
  IconsCards,
  IconsContainer,
  Title,
  IconTitle,
  IconText,
  TrajectoryContainer,
  TrajectoryImg,
  TrajectoryTitle,
  TrajectoryText,
  TrajectoryTextContainer,
  TimeLineContainer,
  VideoContainer,
  VideoRep,
  PrinciplesContainer,
  PrinciplesCardsContainer,
  PrinciplesCards,
  PrinciplesText,
  PrinciplesIcon,
  PrinciplesIconContainer,
  PrinciplesTitle,
  PrinciplesTextContainer,
  PageContainer,
} from "./styles";
import { Helmet } from "react-helmet-async";

export const Sobre = () => {
  const SobreImg = require("../../assets/Images/Banners_site/banner-historia.webp");
  const ImgMobileSobre = require("../../assets/Images/Banners-mobile/banner-nossa-historia.webp");
  const trajectoryImg = require("../../assets/Images/NossaHistoria/mapa.webp");

  const menuIcons = [
    {
      id: 1,
      icon: collaborators,
      title: (
        <>
          <b>
            + DE <strong>1400</strong>
          </b>
          <b>COLABORADORES</b>
        </>
      ),
      text: " ",
      alt: "Colaboradores",
    },
    {
      id: 2,
      icon: store,
      title: (
        <>
          <b>
            + DE <strong>600</strong>
          </b>
          <b> LOJAS ABERTAS</b>
        </>
      ),
      text: " ",
      alt: "Lojas Abertas",
    },
    {
      id: 3,
      icon: graphic,
      title: (
        <>
          <b>
            + DE <strong>777</strong>
          </b>
          <b>
            <strong>MILHÕES</strong> DE FATURAMENTO
          </b>
        </>
      ),
      text: " ",
      alt: "Faturamento",
    },
    {
      id: 4,
      icon: location,
      title: (
        <>
          <b>PRESENTE EM</b>
          <strong>13 ESTADOS</strong>
          <b />
        </>
      ),
      text: "  ",
      alt: "Estados",
    },
  ];
  const principlesOdd = [
    {
      id: 1,
      icon: graph,
      title: "Faça o seu melhor",
      text: "Em todas as ações do seu dia, e em tudo o que você faz, você se pergunta se está fazendo o seu melhor? Seja com amigos, família, religião e principalmente trabalho, se cobre e faça o seu melhor! É assim que você se desenvolve! Somos uma empresa que estamos em melhoria contínua e mudando sempre, mas uma coisa que nunca irá mudar é que sempre vamos fazer o nosso MELHOR! PROGRESSO = Disciplina + Trabalho Duro!",
      alt: "Faça o seu melhor",
    },

    {
      id: 2,
      icon: collaboratorStar,
      title: "Faça o melhor para o cliente",
      text: "Fazer o nosso melhor para o cliente sempre nos colocará na direção certa. Toda vez em que você tiver alguma dúvida sobre sua postura com o cliente, pergunte-se: Essa é a maneira que eu gostaria de ser atendido? Essa é postura que eu gostaria que as pessoas que eu mais amo, fossem atendidas? Basta refletir sobre isso. Existe apenas uma pessoas que pode tirar qualquer um do jogo, essa pessoa é o CLIENTE!",
      alt: "Faça omelhor para o cliente",
    },
    {
      id: 3,
      icon: trophy,
      title: "Sucesso NÃO aceita preguiça",
      text: "Não toleramos vagabundagem! E o que isso significa? Errar faz parte do processo de desenvolvimento, todos erramos. Mas, não podemos gostar dessa dor. Sinta-a uma vez, para não acontecer mais. Não aceitamos pessoas que só culpam os outros e não olham os próprios erros. Não tenha preguiça com você. Adiar e postergar as coisas é adiar o seu desenvolvimento, seus sonhos e o nosso progresso. Faça o quanto antes puder. Organize, se planeja e EXECUTE.",
      alt: "Sucesso NÃO aceita preguiça",
    },

    {
      id: 4,
      icon: mentor,
      title: "Saiba sua posição: Professor X Aluno",
      text: "Em toda conversa dentro da empresa, pergunte-se naquele momento se você é um professor ou aluno no assunto. Saiba se posicionar e respeitar as posições de conhecimento. Tenha ATITUDE para mostrar aquilo que você domina, mas também tenha HUMILDADE, para ouvir e aprender sempre que necessário.",
      alt: "Saiba sua posição: Professor X Aluno",
    },
    {
      id: 5,
      icon: community,
      title: "Coletivismo > individualismo",
      text: "O talento vence jogos, mas só o trabalho em equipe vence campeonatos. A função dos membros da equipe é sempre cooperar com o time. O individualismo pode ser visto na competitividade saudável entre as pessoas. É o que chamamos de COOPETIÇÃO. Cooperar + Competir para melhorar!",
      alt: " Coletivismo > individualismo",
    },

    {
      id: 6,
      icon: handshake,
      title: "Nós somos um time",
      text: "Respeito, convívio, amizade e bons relacionamentos. Em alguns casos passamos mais tempo com as pessoas no trabalho do que com pessoas de nossa família. Isso se dá pois criamos laços e vínculos com as pessoas, e isso é muito bom. Quem que não gosta de trabalhar com pessoas que preenchem nosso dia e deixam ele melhor? Seja essa pessoa que sempre melhora o dia de alguém e quer o melhor de quem está ao seu lado!",
      alt: "Nós somos um time",
    },
    {
      id: 7,
      icon: collaboratorStairs,
      title: "Stay hungry, stay foolish",
      text: "Acreditamos em melhoria e temos um sonho grande! Odiamos pessoas conformadas e preguiçosas em sua zona de conforto. Entenda, existem milhares de coisas para melhorar a sua vida e a vida de alguém. Você precisa ter humildade para sempre aprender e continuar faminto para sempre querer mais. Entenda novamente, querer mais, querer melhor, não é só em relação a dinheiro, abra a cabeça e queira mais e melhor do mundo em que você vive!",
      alt: "Stay hungry, stay foolish",
    },

    {
      id: 8,
      icon: collaboratorFlag,
      title: "Falou, cumpriu",
      text: "Responsabilidade e Transparência. A sua palavra deve ter o poder de valer mais do que qualquer contrato ou documento. Tenha lealdade com a sua palavra e valorize o tempo do outro. Dê prioridade no que importa, não deixe seu tempo ao léu para os outros decidam.",
      alt: "Falou, cumpriu",
    },
    {
      id: 9,
      icon: speedometer,
      title: "Velocidade > perfeição",
      text: "Nossa empresa sempre crescerá muito e rápido ao mesmo tempo e para continuar entregando precisamos sempre lembrar que velocidade vale mais do que perfeição. FEITO na maioria das vezes é melhor que perfeito. Você precisa ser ágil e rápido, e isso não significa que tenha que entregar algo sem valor. Lembre-se, se planejar, saber priorizar e fazer o seu melhor será a fórmula para entregar aquilo que precisa ser feito!",
      alt: "Velocidade > perfeição",
    },

    {
      id: 10,
      icon: gear,
      title: "Performance no caórdico",
      text: "Caórdico não é caótico. Caórdico é quando há caos e ordem simultaneamente, na medida certa, onde a empresa cresce de forma acelerada. Manter a performance nesse estado é como jogar um jogo com mais dificuldade, no modo expert, mas nesse caso, com mais resultados! 🚀",
      alt: "Performance no caórdico",
    },
  ];

  return (
    <PageContainer>
      <Helmet>
        <title>The Best Açaí - Nossa história</title>
        <link rel="canonical" href={`https://thebestacai.com.br/sobre`} />
        <meta property="og:image" content={SobreImg} />
        <meta name="twitter:image" content={SobreImg} />
        <meta name="twitter:card" content="summary" />
      </Helmet>
      <Navbar />
      <BannerHome
        img={SobreImg}
        alt="Banner da página Sobre nós. Na foto, imagens de uma franquia"
        text={false}
        imgMobile={ImgMobileSobre}
      />
      <Container>
        <AboutContainer>
          <Title className="titleFirst">
            Quem <strong>somos?</strong>
          </Title>

          <IconsContainer>
            {menuIcons.map((cards) => (
              <IconsCards key={cards.id}>
                <Icons src={cards.icon} alt={cards.alt} />
                <IconTitle>{cards.title}</IconTitle>
                <IconText>{cards.text}</IconText>
              </IconsCards>
            ))}
          </IconsContainer>
        </AboutContainer>

        <TrajectoryContainer>
          <TrajectoryImg
            src={trajectoryImg}
            alt="Foto do mapa do Brasil, com pontos onde tem unidades do The Best Açaí"
          />
          <TrajectoryTextContainer>
            <TrajectoryTitle>
              Stay <strong>hungry</strong>, stay <strong>foolish</strong>.
            </TrajectoryTitle>
            <TrajectoryText>
              <p>
                A The Best Açaí nasceu em 2017 a partir da força de vontade e
                olhar visionário de três jovens empreendedores e tem como
                propósito democratizar o consumo do açaí. Com a premissa de
                oferecer qualidade e diversidade de opções em torno da fruta em
                um modelo self-service focado na experiência do consumidor, a
                rede tem mais de 600 lojas – sendo 20 próprias – no Paraná, São
                Paulo, Santa Catarina, Mato Grosso, Mato Grosso do Sul, Goiás,
                Minas Gerais e Rio Grande do Sul.
              </p>
              <br></br>
              <p>
                A Amadelli, fábrica e distribuidora dos produtos The Best Açaí,
                está localizada em Londrina, no Paraná, e produz 20 toneladas de
                açaí e sorvete diariamente. A estrutura agrega 1.900 m² de área
                construída e tecnologia de ponta que garante a qualidade e o
                sabor incomparável dos produtos encontrados em todas as lojas
                The Best Açaí. Fazem parte do Grupo The Best a marca The Best
                Açaí, a Amadelli, o Gracco Burguer e o Gracco Express. Acesse o
                maior Instagram de açaí do mundo:{" "}
                <a
                  href="https://www.instagram.com/thebestacaiofficial/"
                  target="_blank"
                  rel="noreferrer"
                >
                  @thebestacaiofficial
                </a>
              </p>
            </TrajectoryText>
          </TrajectoryTextContainer>
        </TrajectoryContainer>

        <TimeLineContainer>
          <Title>Linha do tempo</Title>
          <TimeLine />
        </TimeLineContainer>

        <VideoContainer>
          <Title>Conheça mais sobre nós!</Title>

          <VideoRep src="https://www.youtube.com/embed/QhHpdbbk63g">
            Seu navegador não suporta HTML5.
          </VideoRep>
        </VideoContainer>
        <PrinciplesContainer>
          <Title className="titlePrinciples">Princípios da The Best</Title>
          <PrinciplesCardsContainer>
            {principlesOdd.map((cards) => (
              <PrinciplesCards key={cards.id}>
                <PrinciplesIconContainer>
                  <PrinciplesIcon src={cards.icon} alt={cards.alt} />
                </PrinciplesIconContainer>
                <PrinciplesTextContainer>
                  <PrinciplesTitle>{cards.title}</PrinciplesTitle>
                  <PrinciplesText>{cards.text}</PrinciplesText>
                </PrinciplesTextContainer>
              </PrinciplesCards>
            ))}
          </PrinciplesCardsContainer>
        </PrinciplesContainer>
      </Container>

      <Footer active={true} />
    </PageContainer>
  );
};
